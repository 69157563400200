import React from "react"
import { Link } from "gatsby"

function AboutUsPageComponent({ name, description, coverImage }) {
  return (
    <div class="uk-cover-container about-us">
      <img src={coverImage} alt="" data-uk-cover />
      <div class="uk-overlay-primary uk-position-cover"></div>
      <div class="uk-overlay uk-position-center-left uk-light w-100">
        <div
          className="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s"
          data-uk-grid
        >
          <div className="uk-flex uk-flex-column uk-flex-center">
            <h1 className="uk-h1 uk-text-uppercase">{name}</h1>
            <div className="uk-width-large">{description}</div>
          </div>

          <div className="uk-flex uk-flex-column items-end-ns items-start">
            <div className="uk-flex uk-flex-column uk-margin-right ">
              <Link
                className="uk-button uk-button-secondary uk-button-small"
                style={{ margin: "10px" }}
                to="/about/who-are-we"
              >
                Who Are We?
              </Link>
              <Link
                className="uk-button uk-button-secondary uk-button-small"
                style={{ margin: "10px" }}
                to="/about/infrastructure"
              >
                Infrastructure
              </Link>
              <Link
                className="uk-button uk-button-secondary uk-button-small"
                style={{ margin: "10px" }}
                to="/about/our-core-values"
              >
                Our Core Values
              </Link>
              <Link
                className="uk-button uk-button-secondary uk-button-small"
                style={{ margin: "10px" }}
                to="/about/our-leadership"
              >
                Our Leadership
              </Link>
              <Link
                className="uk-button uk-button-secondary uk-button-small"
                style={{ margin: "10px" }}
                to="/about/our-responsibility"
              >
                Our Responsibilities
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUsPageComponent
