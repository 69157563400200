import React from "react"
import { Link } from "gatsby"
import StandardLayout from "../../layouts/standard"
import coverImage from "../../images/about/responsibility-cover.png"
import AboutUsPageComponent from "../../components/AboutUsPageComponent"

export default function OurResponsibility(props) {
  return (
    <StandardLayout title="Our Responsibility">
      <AboutUsPageComponent
        name="Our Responsibility"
        description={`Ethically sourced products, sustainable design that positively impacts our global community.`}
        coverImage={coverImage}
      />

      <section className="uk-section">
        <div className="uk-container">
          <div>
            <div className="uk-card uk-card-default uk-padding-small">
              <img src={coverImage} className="uk-width-1-1" alt="" />
              <div className="uk-margin">
                <span className="color-primary">Stylam</span> owes its
                prosperity to its kin who gave everything to make the
                organization what it is today. We are an organization that
                trusts in the force of individuals and in engaging individuals.
                This is the motivation behind why we have embraced various
                wellbeing and training educational activities. Through our work
                for poor people and underprivileged, we are helping in creating
                a society of equals under the village adoption scheme
                appropriation plot, we have recently adopted a model Village
                Abheypur for development objectives. This initiative is to
                energize the rural economy in India and to slow down rural-urban
                migration.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div
          class="uk-cover-container"
          style={{ minHeight: "60vh", height: "auto" }}
        >
          <img src={coverImage} alt="" data-uk-cover />
          <div class="uk-overlay-primary uk-position-cover"></div>
          <div class="uk-overlay uk-position-center">
            <div className="uk-container">
              <div>
                <div className="uk-card uk-card-default uk-padding-large">
                  <div className="uk-text-center">
                    <h2 className="uk-h2 uk-text-uppercase">Have Queries?</h2>
                    <div className="uk-width-large" style={{ margin: "auto" }}>
                      Connect with us for any query you need resolved and we’ll
                      help you realize your ideas.
                    </div>
                    <Link
                      className="color-primary uk-text-large"
                      to="/contact-us"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StandardLayout>
  )
}
